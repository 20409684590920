.cursor-pointer {
  cursor: pointer;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.searchIcon input:focus{
  outline: none !important;
  border: none !important;
}

.react-autosuggest__container {
  position: relative;
  height: fit-content;
  margin: auto 0;
  width: calc(100% - 32px);
}

.react-autosuggest__input {
  width: 150px;
  height: 30px;
  padding: 10px 20px;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: none;
  /*display: block;*/
  position: absolute;
  top: 32px;
  width: 280px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-family: Helvetica, sans-serif;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 2;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__input {
  width: 100%;
  border: none;
  padding: 0;
}

.autosuggest-container {
  display: flex;
  border-radius: 8px;
  border: solid 1px #e1e3e4;
  background-color: #ffffff;
  padding: 0 16px;
  width: 60%;
}

.autosuggest-container .search-margin {
  margin: auto 0 auto auto;
}

.back-button {
  width: 10%;
  margin: auto auto auto 0;
  color: #e1e3e4;
}

.back-button button {
  color: #1e2a5a !important;
}

.back-button button:hover {
  background-color: white;
}

.back-button .back-icon {
  color: #838faa;
  border-radius: 50%;
  border: 1px solid #e1e3e4;
  margin-right: 10px;
  height: 26px;
  width: 26px;
  display: flex;
}



.color-99 {
  color: #999999;
}

.margin-auto {
  margin: auto;
}

.mr-12 {
    margin-right: 12px;
}

.width-100 {
    width: 100%;
}

.max-width-100 {
  max-width: 100%;
}
.scope-100{
    width: 100%;
    height: 100%;
}

.rightInfo {
  text-align: left;
  width: calc(65% - 32px);
  color: #1b1b1b;
}

.pagination-icon {
  font-size: 18px !important;
}

.rightInfo .MuiTextField-root {
  width: 100%;
}

.rightInfo .MuiInput-underline:before {
  border-bottom: none;
}

.rightInfo span {
  display: none;
}

.rightInfo:hover span {
  display: inline;
  margin-left: 6px;
}

.display-inline {
  display: inline;
}
.display-inline-block {
    display: inline-block;
}

.display-flex {
  display: flex;
}

.display-block {
    display: block;
}

.margin-auto {
  margin: auto;
}

.align-center {
  align-items: center;
}

.menuItem {
  font-size: 15px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.18;
  letter-spacing: normal;
  text-align: left;
  color: #1e2a2a;
  padding: 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-bottom-border {
    border-bottom: 2px solid #e1e3e4;
}

.display-none {
  display: none;
}

.done-icon {
  color: #52ee37;
  font-size: 18px;
}

.settings-menu span {
  font-size: 14px !important;
  color: #141b2d;
  font-family: "Open Sans" !important;
  font-weight: normal !important;
}

.upload-more {
  font-family: Raleway;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: center;
  color: #1e2a5a;
  margin: 1rem 2.5rem;
}

.upload-files {
    font-family: Raleway, sans-serif;
  font-size: 26px;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #5fafff;
  margin-bottom: 24px;
}

.capButton {
    background-color: #ebebeb;
    border-radius: 8px;
    display: flex;
    padding: 12px;
    cursor: pointer;
}

.capButton span {
    font-family: OpenSans;
    font-size: 17px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #686868;
    display: flex;
}

.capButton .right-icon {
    font-size: 18px;
    margin: auto 0 auto auto;
    color: #959daf;
}

.cap-modal-footer {
    display: flex;
    padding: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.35);
}

.confirm-btn {
    font-family: OpenSans;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #959daf;
    cursor:pointer;
}

.cancel-btn {
    font-family: OpenSans;
    font-size: 13px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #d3d3d3;
    opacity: 0.75;
    margin-left: auto;
    margin-right: 16px;
    cursor:pointer;
}

.chips-row {
    display: flex;
}

.edit-cap {
    font-family: 'Open Sans';
    font-size: 11px;
    font-weight: 800 !important;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #959daf;
    margin-left: 16px;
    cursor: pointer;
}

.check-box-cap {
    width: 50%;
    display:inline-block;
}

.select-capabilities {
    font-family: OpenSans;
    font-size: 20px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.1;
    letter-spacing: normal;
    text-align: left;
    color: #141b2d;
    margin: auto 0;
}

.select-deselect {
    border-radius: 18.4px;
    border: solid 1px #e1e3e4;
    background-color: #ffffff;
    padding: 8px;
    font-family: OpenSans;
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: center;
    color: #999999;
    margin-left: auto;
    cursor:pointer;
}

.select-cap-header {
    display: flex;
    margin-bottom: 16px;
}

.overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.users-table .MuiTableCell-root {
    padding-right: 0 !important;
}

.swagger-loader {
    position: relative;
    left: calc(50% - 20px);
    top: 40px;
}

@media screen and (min-width: 1800px){
    .title-tabs-margin {
        margin-right: 110px !important;
    }
}

.min-width-app {
    min-width: 1400px !important;
}
.position-relative {
    position: relative;
}

.text-center {
    text-align: center;
}

.upload-modal-scroll:first-child {
    position: relative !important;

}

/*.upload-modal-scroll div:nth-child(1) {
    position: unset !important;
}*/

.upload-modal-scroll {
    display: flex;
}

[class*="FileUpload-minimizeModal-"]{
    -webkit-box-shadow: 0px 0px 34px -12px rgba(0,0,0,0.54);
    -moz-box-shadow: 0px 0px 34px -12px rgba(0,0,0,0.54);
    box-shadow: 0px 0px 34px -12px rgba(0,0,0,0.54);
    }

.MuiBreadcrumbs-ol li:last-child p {
    /*display: none;*/
    font-weight: bold !important;
}

.custom-scrollbar::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #edeeef;
    border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #d7d9db;
    border-radius: 10px;
}

.workspace-header {
    position: fixed;
    /*width: calc(100% - 112px);*/
    width: 100%;
    left: 0;
    padding: 10px 56px 0 56px;
    background-color: #f4f6f9;
    z-index: 101;
}

.workspace-tabs {
    /*position: fixed;*/
    /*!*margin-left: 94px;*!*/
    /*left: 280px;*/
}

.file-menu-div {
    position: fixed;
}

.route-cntr {
    margin-top: 70px;
}

.workspace-padding-top {
    padding-top: 10px;
}
[class*="Organization-apiKeys-"] .MuiInput-underline.Mui-disabled:before {border-bottom:0 #fff !important;}

.orgSteps .MuiStepConnector-root{display:none;}
.orgSteps .MuiStepper-root{padding:5px !important; color:#ccc; width:30px; height: 10px; font-size:0!important;}
.orgSteps .MuiStepper-root .MuiStepIcon-root{opacity: 0;}
.orgSteps .MuiStep-horizontal{padding:0 !important; margin:10px 0;}
.orgSteps .MuiStepLabel-iconContainer {width: 45px; background: #e1e3e4; opacity: 0.9; margin-right: 10px; height: 10px;}
.stepForm{margin-top:-15px;}
.stepForm .description-box #description-helper-text{margin-bottom:0;}
.stepForm {flex-direction:row;}
.prevNext{border-top:1px solid #ccc; flex-direction:row; margin-top:30px; text-align:right; padding-top:10px}
.orgSteps .MuiStepper-horizontal{width:auto; margin-left:55%;}
.orgSteps .MuiStepIcon-root.MuiStepIcon-completed, .orgSteps .MuiStepIcon-root.MuiStepIcon-active {width: 45px; height: 10px; background: #5da6fe;
    opacity: 0.9; border-radius:10px!important;}
.orgSteps .MuiPaper-root{background:none;}
.orgSteps .MuiStepIcon-root.MuiStepIcon-completed, .orgSteps .MuiStepIcon-root.MuiStepIcon-active {color: #5da6fe!important;}
.orgSteps .MuiStepIcon-text {display:none;}

.orgSteps .MuiStepLabel-iconContainer{border-radius:10px!important;}
.orgSteps .MuiStepIcon-root.MuiStepIcon-completed {color: #5da6fe;}
.orgSteps .MuiStepLabel-iconContainer{border-radius:0;}
.stepTwo .MuiFormLabel-root{line-height:0!important;}
.stepThree .MuiInput-underline::before{border-bottom:0;}
.stepFour .MuiInput-underline::before{border-bottom:0;}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    [class*="makeStyles-text-"]{
    color: #777777;
    font-size: 11px;
    font-weight: 900;
  }
}

.minimizeModal {
    bottom: 8%;
    left: 5%;
    position: fixed!important;
    background: white;
    Z-index: 999;
    -webkit-box-shadow: 0px 0px 44px -11px rgba(0,0,0,0.55);
    -moz-box-shadow: 0px 0px 44px -11px rgba(0,0,0,0.55);
    box-shadow: 0px 0px 44px -11px rgba(0,0,0,0.55);
  }

div.create-org-modal > div:first-child{background:rgba(0, 0, 0, 0.8)!important;}

div.add-super-admin-modal > div:first-child{background:rgba(0, 0, 0, 0.8)!important;}

.react-select-timezone div {
  min-height: 38px;
  border-radius: 10px;
  line-height: 38px;
}

.MuiChip-root{margin-right:10px; background: #ebebeb!important; margin-bottom:2px;}

.menu-content{position : fixed!important; top: 78px!important; height: calc(100vh - 78px)!important; }

.api-content{margin-left : 260px!important; float : left; margin-top: 100px!important;}

.margin{margin:7px}
.padding{padding:0 8px}