/* Not really sure why, but the inverse version has a bigger gap */
/* line 42, ../src/scss/_mixins.scss */
.grow-from-left-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}
/* line 47, ../src/scss/_mixins.scss */
.grow-from-left-enter.grow-from-left-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* line 52, ../src/scss/_mixins.scss */
.grow-from-left-leave {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}
/* line 57, ../src/scss/_mixins.scss */
.grow-from-left-leave.grow-from-left-leave-active {
    -webkit-transform: scale(0);
    transform: scale(0);
}

/* line 42, ../src/scss/_mixins.scss */
.grow-from-right-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}
/* line 47, ../src/scss/_mixins.scss */
.grow-from-right-enter.grow-from-right-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* line 52, ../src/scss/_mixins.scss */
.grow-from-right-leave {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 0;
    transform-origin: 100% 0;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}
/* line 57, ../src/scss/_mixins.scss */
.grow-from-right-leave.grow-from-right-leave-active {
    -webkit-transform: scale(0);
    transform: scale(0);
}

/* line 42, ../src/scss/_mixins.scss */
.grow-from-center-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}
/* line 47, ../src/scss/_mixins.scss */
.grow-from-center-enter.grow-from-center-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* line 52, ../src/scss/_mixins.scss */
.grow-from-center-leave {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}
/* line 57, ../src/scss/_mixins.scss */
.grow-from-center-leave.grow-from-center-leave-active {
    -webkit-transform: scale(0);
    transform: scale(0);
}

/* line 42, ../src/scss/_mixins.scss */
.grow-from-up-left-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}
/* line 47, ../src/scss/_mixins.scss */
.grow-from-up-left-enter.grow-from-up-left-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* line 52, ../src/scss/_mixins.scss */
.grow-from-up-left-leave {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0 100%;
    transform-origin: 0 100%;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}
/* line 57, ../src/scss/_mixins.scss */
.grow-from-up-left-leave.grow-from-up-left-leave-active {
    -webkit-transform: scale(0);
    transform: scale(0);
}

/* line 42, ../src/scss/_mixins.scss */
.grow-from-up-center-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}
/* line 47, ../src/scss/_mixins.scss */
.grow-from-up-center-enter.grow-from-up-center-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* line 52, ../src/scss/_mixins.scss */
.grow-from-up-center-leave {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 50% 100%;
    transform-origin: 50% 100%;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}
/* line 57, ../src/scss/_mixins.scss */
.grow-from-up-center-leave.grow-from-up-center-leave-active {
    -webkit-transform: scale(0);
    transform: scale(0);
}

/* line 42, ../src/scss/_mixins.scss */
.grow-from-up-right-enter {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    transition: -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
    transition: transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8), -webkit-transform 0.15s cubic-bezier(0.5, 1.8, 0.9, 0.8);
}
/* line 47, ../src/scss/_mixins.scss */
.grow-from-up-right-enter.grow-from-up-right-enter-active {
    -webkit-transform: scale(1);
    transform: scale(1);
}

/* line 52, ../src/scss/_mixins.scss */
.grow-from-up-right-leave {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 100% 100%;
    transform-origin: 100% 100%;
    transition: -webkit-transform 0.15s ease-out;
    transition: transform 0.15s ease-out;
    transition: transform 0.15s ease-out, -webkit-transform 0.15s ease-out;
}
/* line 57, ../src/scss/_mixins.scss */
.grow-from-up-right-leave.grow-from-up-right-leave-active {
    -webkit-transform: scale(0);
    transform: scale(0);
}

/* line 2, ../src/scss/_dd-menu.scss */
.dd-menu {
    display: inline-block;
    position: relative;
    width: calc(100% - 40px);
}
/* line 6, ../src/scss/_dd-menu.scss */
.dd-menu.dd-menu-center .dd-menu-items {
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
}
/* line 18, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-left .dd-menu-items {
    top: 0;
    left: 110%;
    box-shadow: 0 4px 6px 0 rgba(184, 191, 196, 0.75);
    border: 1px solid #e1e3e4;
    border-radius: 10px;
    margin-bottom :20px;
}

.dd-menu.dd-menu-left .dd-menu-items:after{width:200px; height:30px; background:transparent; position:absolute; bottom:-28px; left:0; content: "";
    display: block;}

/* line 5, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-left .dd-menu-items [class^='nested-']:not(.nested-reverse) > span {
    right: 100%;
    padding-right: 0.5em;
}
/* line 10, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-left .dd-menu-items .nested-reverse > span {
    left: 125%;
    padding-left: 0.5em;
    top: -16px;
}

.dd-menu.dd-menu-left .dd-menu-items .nested-reverse > span ul {
    box-shadow: 0 4px 6px 0 rgba(184, 191, 196, 0.75);
    border: 1px solid #e1e3e4;
    border-radius: 10px;
}
/* line 5, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-left.dd-menu-inverse .dd-menu-items [class^='nested-']:not(.nested-reverse) > span {
    right: 100%;
    padding-right: 0.3em;
}
/* line 10, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-left.dd-menu-inverse .dd-menu-items .nested-reverse > span {
    left: 125%;
    padding-left: 0.3em;
    top: -16px;
}
/* line 18, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-right .dd-menu-items {
    right: 0;
}

/* line 5, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-right .dd-menu-items [class^='nested-']:not(.nested-reverse) > span {
    left: 125%;
    padding-left: 0.5em;
    top: -16px;
}
/* line 10, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-right .dd-menu-items .nested-reverse > span {
    right: 100%;
    padding-right: 0.5em;
}
/* line 5, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-right.dd-menu-inverse .dd-menu-items [class^='nested-']:not(.nested-reverse) > span {
    left: 125%;
    padding-left: 0.3em;
    top: -16px;
}
/* line 10, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-right.dd-menu-inverse .dd-menu-items .nested-reverse > span {
    right: 100%;
    padding-right: 0.3em;
}
/* line 30, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-sm .dd-menu-items {
    width: 150px;
}
/* line 30, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-md .dd-menu-items {
    width: 300px;
}
/* line 30, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-lg .dd-menu-items {
    width: 450px;
}
/* line 30, ../src/scss/_mixins.scss */
.dd-menu.dd-menu-xl .dd-menu-items {
    width: 600px;
}
/* line 15, ../src/scss/_dd-menu.scss */
.dd-menu .dd-menu-items {
    position: absolute;
    z-index: 7;
    margin: 0.5em 0 0 0;
}
/* line 20, ../src/scss/_dd-menu.scss */
.dd-menu .dd-menu-items.dd-items-upwards {
    bottom: 100%;
    margin: 0 0 0.5em;
}
/* line 25, ../src/scss/_dd-menu.scss */
.dd-menu .dd-menu-items ul, .dd-menu .dd-menu-items ol {
    list-style: none;
    padding: 0;
    margin: 0;
    color: #000;
    background-color: #fefefe;
    width: calc(100% - 1px);
    border-radius: 10px;
    //box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 0 2px 4px rgba(0, 0, 0, 0.29);
}
/* line 34, ../src/scss/_dd-menu.scss */
.dd-menu .dd-menu-items ul.dd-items-right li > *, .dd-menu .dd-menu-items ol.dd-items-right li > * {
    text-align: right;
}
/* line 38, ../src/scss/_dd-menu.scss */
.dd-menu .dd-menu-items ul.dd-items-left li > *, .dd-menu .dd-menu-items ol.dd-items-left li > * {
    text-align: left;
}

.dd-items-center span{
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
/* line 42, ../src/scss/_dd-menu.scss */
.dd-menu .dd-menu-items ul.dd-items-center li > *, .dd-menu .dd-menu-items ol.dd-items-center li > * {
    //text-align: center;
}
/* line 48, ../src/scss/_dd-menu.scss */
.dd-menu .dd-menu-items ul li:hover:not([role="separator"]):not(.separator),
.dd-menu .dd-menu-items ul li > *:focus, .dd-menu .dd-menu-items ol li:hover:not([role="separator"]):not(.separator),
.dd-menu .dd-menu-items ol li > *:focus {
    color: #000;
    background-color: rgba(0, 0, 0, 0.08);
    outline: none;
}

.dd-menu .dd-menu-items ul li > *:focus {
  background-color: unset !important;
}

/* line 55, ../src/scss/_dd-menu.scss */
.dd-menu .dd-menu-items ul li > *:not(.dd-item-ignore), .dd-menu .dd-menu-items ol li > *:not(.dd-item-ignore) {
    display: block;
    width: auto;
    /*min-width: 100%;*/
    /*padding: 1em 1.5em;*/
    font-family: Roboto, sans-serif;
    white-space: pre;
    color: inherit;
    text-decoration: none;
    font-size: inherit;
}
/* line 68, ../src/scss/_dd-menu.scss */
.dd-menu .dd-menu-items ul li > button, .dd-menu .dd-menu-items ol li > button {
    border: none;
    background: transparent;
}
/* line 78, ../src/scss/_dd-menu.scss */
.dd-menu.dd-menu-inverse .dd-menu-items ul, .dd-menu.dd-menu-inverse .dd-menu-items ol {
    color: #fff;
    background-color: #2c3e50;
}
/* line 84, ../src/scss/_dd-menu.scss */
.dd-menu.dd-menu-inverse .dd-menu-items ul li:hover:not([role="separator"]):not(.separator),
.dd-menu.dd-menu-inverse .dd-menu-items ul li > *:focus, .dd-menu.dd-menu-inverse .dd-menu-items ol li:hover:not([role="separator"]):not(.separator),
.dd-menu.dd-menu-inverse .dd-menu-items ol li > *:focus {
    color: #fff;
    background-color: #34495e;
}
/* line 93, ../src/scss/_dd-menu.scss */
.dd-menu.dd-menu-inverse [role="separator"], .dd-menu.dd-menu-inverse .separator {
    background-color: #45595e;
}
/* line 98, ../src/scss/_dd-menu.scss */
.dd-menu [role="separator"], .dd-menu .separator {
    content: '';
    display: block;
    height: 2px;
    background-color: rgba(0, 0, 0, 0.15);
    margin: 0.5em 0 0.5em 0;
}
/* line 107, ../src/scss/_dd-menu.scss */
.dd-menu .dd-items-upwards li.nested-dd-menu > span {
    bottom: 0;
    top: initial;
}
/* line 113, ../src/scss/_dd-menu.scss */
.dd-menu li.nested-dd-menu {
    position: relative;
}
/* line 116, ../src/scss/_dd-menu.scss */
.dd-menu li.nested-dd-menu > span {
    position: absolute;
    top: 0;
}

/*# sourceMappingURL=react-dd-menu.css.map */
/*# sourceMappingURL=react-dd-menu.css.map */

.rightFloat {
    position: absolute;
    bottom: -6px;
    right: -26px;
}

.rightFloat svg {
    color: #959daf;
}

.nested-li{
    position: relative;
    min-width: 200px;
    padding: 0.5rem 2rem 0.5rem 1rem;
    .span{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: left;
    }
}
.normal-li{
    width: 100%;
    padding: 0.5rem 2rem 0.5rem 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.moveIcon{
    margin-bottom: -7px;
    margin-left: 22px;
    color: #959daf;
}

.sortDiv{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    .sortItem:hover {
        cursor: pointer;
    }
    .sortItem{
        z-index: 100;
        display: flex;
        font-size: 0.75rem;
        //border-bottom: 2px solid #5EAFFF;
        .sortIcon{
            width: 1.25rem;
            height: 1.25rem;
            color: #5EAFFF;
          margin-top: auto;
          margin-bottom: auto;
        }
    }
}
.listSortIcon{
    width: 1.25rem !important;
    height: 1.25rem !important;
    color: #5EAFFF;
    z-index: 100;
    display: flex;
    font-size: 0.75rem;
    justify-content: space-between;
    align-items: baseline;
}
.listSortIcon:hover {
    cursor: pointer;
}
.mainHeader{
    //display: flex !important;
    margin-left: 0.7rem;
    margin-bottom: 0.5rem;

    .nodeFolderIcon{
        color: #959daf;
        margin: 0 0.5rem;
    }
    .nodeArrowIcon{
        color: #4f4ff7;
        margin-top: -7px;
    }
}

.nodeFldIcon{
     color: #d3d3d3!important;
        //margin-top: -7px;
        margin-right:10px;
    }
.subDrop{
    display:flex!important;
}
.hovePointer:hover{
    cursor: pointer;
}

.upload-icon {

}

.pageContent1::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.pageContent1::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #edeeef;
  border-radius: 10px;
}

.pageContent1::-webkit-scrollbar-thumb {
  background: #d7d9db;
  border-radius: 10px;
}
.container::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

.container::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #edeeef;
  border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
  background: #d7d9db;
  border-radius: 10px;
}
.nodeText{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.trashSearch{
    position: fixed;
    background: white;
    padding-top: 24px;
    z-index: 999;
    //width: calc(83% - 286px) !important;

    //position: fixed;
    left: calc(17% + 74px) ;
    //background: white;
    //padding-top: 24px;
    padding-left: 5rem;
    //z-index: 999;
    width: calc(83% - 130px);
    padding-right: 5rem;
}
.trashScroll{
    padding-top: 144px ;
}
.fileScroll{
    padding-top: 172px !important;
}
.minScroll{
    min-height: calc(100vh - 526px);
    align-content: flex-start;
}
.footerFixed{
    min-height: calc(100vh - 526px);
    align-content: flex-start;
}
.fiveFiles{
    .MuiGrid-grid-sm-2{
        max-width: 20% !important;
        flex-basis: 20% !important;
    }
}

/*[class*="FileUpload-modal-"]:after{
    position:fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    background-color: rgba(0,0,0,0.7);
    content :" ";
    z-index:-1;
}*/

.upload-modal-scroll div:first-child{position:none!important;}

.iconBox{text-align:center; margin-top:15%}

.emptyBox{text-align:center; margin-top:5%; padding-bottom:2rem;}

.gridbox{
    width: 100%;
    text-align: center;
    border: 1px solid #e4e6e7;
    border-radius : 10px;
    padding : 20px;
}

.userimgDiv{max-width:100px; max-height:100px; margin: 0 auto;}
.griduserName{ font-size: 15px;}
.gridbox{position: relative;}
.moreIcon{position:absolute; right: 0; top:10px;}
.userCat {color: #999999; font-size: 10px; margin-top: 10px; text-transform: uppercase; font-weight:bold;}
.userEmail{font-size: 10px;}
.css-1s1o8u8{margin-top:10px;}