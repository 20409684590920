/* Extra small devices (portrait phones, less than 576px)*/
@media (max-width: 575.98px) {
    .min-width-app{min-width:auto!important;}
    .login-main h1{font-size:20px;}
    .login-main .MuiTypography-subtitle2{width:100%;}
    .login-main .Login-paper-3{padding: 72px 40px 16px 40px;}
    .login-main .Login-container-11{margin-top:20%; margin-bottom:7%;}
    .login-main .Reset-paper-3{height:auto; padding: 72px 40px 16px 40px;}
    [class*="Header-toolbar-"] {padding:15px!important;}
    [class*="Header-button2-"] {padding-left: 15px!important;}
    .workspace-header {
        position: inherit;
        /*width: calc(100% - 112px);*/
        width: 100%;
        left: 0;
        padding: 0px 0px 20px 0px;
        background-color: #f4f6f9;
        z-index: 101;
    }
    [class*="WorkSpace-workMain-"] {
        padding: 0 20px!important;
      }
    [class*="WorkSpace-tabHeader-"] {
        padding-left: 0px!important;
        margin: 20px 0 0 0 !important;
        display: block ruby!important;
      }
    [class*="WorkSpace-mainTitle-"]{
        margin: 1.3rem 0 0 0 !important;
    }
    [class*="WorkSpace-titleTabs-"]{
        margin: auto 20px 0 auto!important;
        font-size: 14px!important;
    }
    [class*="WorkSpace-listIcon-"]{
        width: 30px!important;
        height: 30px!important;
        padding: 3px!important;
    }
    [class*="WorkSpace-listIcon-"] .grid-icon{
        font-size: 15px!important;
    }
    [class*="FileManagement-contentGrid-"]{
        padding: 0!important;
        margin: 0 0 20px 0 !important;
    }
    [class*="FileManagement-menu-"]{
        padding: 0!important;
        margin: 0!important;
    }
    [class*="FileManagement-menuItem-"]{
        width: 100%!important;
    }
    [class*="Queue-card-"]{
        margin: 20px 0!important;
        padding: 1rem!important;
    }
    [class*="Queue-processingQueue-"]{
        margin: 0!important;
        padding: 5px 0 0 0 !important;
        font-size: 15px!important;
    }
    [class*="FileUpload-modal-"]{
        min-width: 90%!important;
    }
    /*.MuiToolbar-gutters, .MuiTablePagination-actions{padding-left :0!important;}*/
    .file-menu-div{position:inherit;}
    .file-menu-div .mt-3{margin-top:0!important;}
    .workspace-padding-top{padding-top:0!important;}
    .MuiGrid-spacing-xs-7 > .MuiGrid-item {
        padding: 5px;
    }
    .min-width-app {
        min-width: 100%!important;
    }
    .MuiGrid-spacing-xs-7 > .MuiGrid-item {
        padding: 5px!important;
    }
    .MuiGrid-spacing-xs-7 {
        /* width: calc(100% + 56px); */
        /* margin: -28px; */
    }

    [class*="FileManagement-searchBar-"]{
        width: 100%!important;
    }
    [class*="FileManagement-content-"]{
        padding: 10px!important;
    }
    .trashSearch{
        position: inherit!important;
        background: none;
        padding-top: 0!important;
        z-index: 999;
        left: 0px;
        padding-left: 0!important;
        width: 100%!important;
        padding-right: 0!important;
    }
    .trashScroll{padding-top:0!important;}

    [class*="FileManagement-innerGrid-"] .MuiGrid-spacing-xs-7{
        padding: 0px!important;
        width:100%;
        margin :0;
    }

    [class*="FileManagement-mainGrid-"]>div{
        width:100%;
    }

    /*Account page*/
    .parent-container {
      padding: 17px!important;
    }
    .account-container {
      padding: 20px!important;
    }

    [class*="Account-firstGrid-"]{
        width:100%!important;
        margin-right:0!important;
    }
    .reset-btn{margin-top:20px!important;}

    /*Admin Page*/
    [class*="Admin-adminMain-"]{
       padding: 24px 15px 16px!important;
    }
    [class*="Admin-contentGrid-"]{
       padding-left: 0!important;
    }
    [class*="Admin-content-"]{
       padding: 20px!important;
    }
    [class*="User-searchBar-"]{
       width:100%!important;
        margin-right:0!important;
    }
    [class*="User-filter-"]{
       min-width:auto !important;
       margin-left:0 !important;
       margin-right:10px !important;
    }
    .filterSelect .MuiSelect-selectMenu{padding-right: 28px !important; padding-left: 0 !important;}

    [class*="User-filter-"]:last-child{
        margin-right:0!important;
    }
    [class*="ClientUser-modal-"]{
        width: 300px!important;
        margin-left: -150px!important;
    }
    .userimgDiv .userIcon{margin: auto;}

    [class*="User-firstGrid-"]{
        width:100%!important;
        margin-right:0!important;
    }
    .username{font-size: 1.3rem!important;}
    [class*="User-formInput-"]{width:100%!important;}
    [class*="User-groupSelect-"]{max-width: 100%!important; min-width: 100%!important; width:100%;}
    [class*="Group-searchBar-"]{width:100%!important;}
    [class*="Stat-searchBar-"]{width:100%!important;}
    [class*="FileManagement-marLeft-"]{margin-left:0!important}
 }

/* Small devices (landscape phones, less than 768px)*/
@media (min-width: 576px) and (max-width: 767.98px) {
    .min-width-app{min-width:auto!important;}
    .login-main .Login-paper-3 {padding: 72px 40px 16px 40px;}
    .login-main .Login-container-11{margin-top:7%; margin-bottom:7%;}
    .login-main .Reset-paper-3{height:auto; padding: 72px 40px 16px 40px;}
    [class*="Header-toolbar-"] {padding:15px!important;}
    [class*="Header-button2-"] {padding-left: 15px!important;}
    .workspace-header {
        position: inherit;
        /*width: calc(100% - 112px);*/
        width: 100%;
        left: 0;
        padding: 0px 0px 20px 0px;
        background-color: #f4f6f9;
        z-index: 101;
    }
    [class*="WorkSpace-workMain-"] {
        padding: 0 20px!important;
      }
    [class*="WorkSpace-tabHeader-"] {
        padding-left: 0px!important;
        margin: 20px 0 0 0 !important;
        display: block ruby!important;
      }
    [class*="WorkSpace-mainTitle-"]{
        margin: 1.3rem 0 0 0!important;
        font-size: 1rem!important;
    }
    [class*="WorkSpace-titleTabs-"]{
        margin: auto 20px 0 auto!important;
        font-size: 14px!important;
    }
    [class*="WorkSpace-listIcon-"]{
        width: 30px!important;
        height: 30px!important;
        padding: 3px!important;
    }
    [class*="WorkSpace-listIcon-"] .grid-icon{
        font-size: 15px!important;
    }
    [class*="FileManagement-contentGrid-"]{
        padding: 0!important;
        margin: 0 0 20px 0 !important;
    }
    [class*="FileManagement-menu-"]{
        padding: 0!important;
        margin: 0!important;
    }
    [class*="FileManagement-menuItem-"]{
        width: 100%!important;
    }
    [class*="Queue-card-"]{
        margin: 20px 0!important;
        padding: 1rem!important;
    }
    [class*="Queue-processingQueue-"]{
        margin: 0!important;
        padding: 5px 0 0 0 !important;
        font-size: 15px!important;
    }
    [class*="FileUpload-modal-"]{
        min-width: 90%!important;
    }
    /*.MuiToolbar-gutters, .MuiTablePagination-actions{padding-left :0!important;}*/
    .file-menu-div{position:inherit;}
    .file-menu-div .mt-3{margin-top:0!important;}
    .workspace-padding-top{padding-top:0!important;}
    .MuiGrid-spacing-xs-7 > .MuiGrid-item {
        padding: 5px;
    }
    .min-width-app {
        min-width: 100%!important;
    }
    .MuiGrid-spacing-xs-7 > .MuiGrid-item {
        padding: 5px!important;
    }
    .MuiGrid-spacing-xs-7 {
        /* width: calc(100% + 56px); */
        /* margin: -28px; */
    }

    [class*="FileManagement-searchBar-"]{
        width: 100%!important;
    }
    [class*="FileManagement-content-"]{
        padding: 10px!important;
    }
    .trashSearch{
        position: inherit!important;
        background: none;
        padding-top: 0!important;
        z-index: 999;
        left: 0px;
        padding-left: 0!important;
        width: 100%!important;
        padding-right: 0!important;
    }
    .trashScroll{padding-top:0!important;}

    [class*="FileManagement-innerGrid-"] .MuiGrid-spacing-xs-7{
        padding: 0px!important;
        width:100%;
        margin :0;
    }

    [class*="FileManagement-mainGrid-"]>div{
        width:100%;
    }

    /*Account page*/
    .parent-container {
      padding: 17px!important;
    }
    .account-container {
      padding: 20px!important;
    }

    [class*="Account-firstGrid-"]{
        width:100%!important;
        margin-right:0!important;
    }
    .reset-btn{margin-top:20px!important;}

    /*Admin Page*/
    [class*="Admin-adminMain-"]{
       padding: 24px 15px 16px!important;
    }
    [class*="Admin-contentGrid-"]{
       padding-left: 0!important;
    }
    [class*="Admin-content-"]{
       padding: 20px!important;
    }
    [class*="User-searchBar-"]{
       width:100%!important;
        margin-right:0!important;
    }
    [class*="User-filter-"]{
       min-width:auto !important;
       margin-left:0 !important;
       margin-right:10px !important;
    }
    .filterSelect .MuiSelect-selectMenu{padding-right: 28px !important; padding-left: 0 !important;}

    [class*="User-filter-"]:last-child{
        margin-right:0!important;
    }
    [class*="ClientUser-modal-"]{
        width: 300px!important;
        margin-left: -150px!important;
    }
    .userimgDiv .userIcon{margin: auto;}

    [class*="User-firstGrid-"]{
        width:100%!important;
        margin-right:0!important;
    }
    .username{font-size: 1.3rem!important;}
    [class*="User-formInput-"]{width:100%!important;}
    [class*="User-groupSelect-"]{max-width: 100%!important; min-width: 100%!important; width:100%;}
    [class*="Group-searchBar-"]{width:100%!important;}
    [class*="Stat-searchBar-"]{width:100%!important;}
    [class*="FileManagement-marLeft-"]{margin-left:0!important}
}

/* Medium devices (tablets, less than 992px)*/
@media (min-width: 768px) and (max-width: 991.98px) {
     .min-width-app{min-width:auto!important;}
    .login-main .Login-paper-3 {padding: 72px 40px 16px 40px;}
    .login-main .Login-container-11{margin-top:7%; margin-bottom:7%;}
    .login-main .Reset-paper-3{height:auto; padding: 72px 40px 16px 40px;}
    [class*="WorkSpace-mainTitle-"]{font-size: 1.2rem!important}
    [class*="WorkSpace-titleTabs-"]{margin: auto 20px 0 auto!important}
    [class*="FileManagement-searchBar-"]{width: 100%!important}
    /*Account page*/
    .parent-container {
      padding: 58px!important;
    }
    .account-container {
      padding: 20px!important;
    }
    [class*="Account-firstGrid-"]{
        width:49%!important;
        margin-right:10px!important;
    }
    [class*="Account-firstGrid-"]:last-child{
        margin-right:0px!important;
    }
    [class*="User-searchBar-"]{
       width:100%!important;
        margin-right:0!important;
    }

    [class*="Group-searchBar-"]{
       width:100%!important;
        margin-right:0!important;
    }

}

/* Large devices (desktops, less than 1200px)*/
@media (min-width: 992px) and (max-width: 1199.98px) {
     .min-width-app{min-width:auto!important;}
    .login-main .Login-paper-3 {padding: 72px 40px 16px 40px;}
    .login-main .Login-container-11{margin-top:7%; margin-bottom:7%;}
     /*Account page*/
    .parent-container {
      padding: 58px!important;
    }
    .account-container {
      padding: 20px!important;
    }
    [class*="Account-firstGrid-"]{
        width:49%!important;
        margin-right:10px!important;
    }
    [class*="Account-firstGrid-"]:last-child{
        margin-right:0px!important;
    }
}

/*Extra large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
	.min-width-app{min-width:auto!important;}
}

