#topCanvas{
    position: absolute;
    top: 0;
    left: 0;
}
#middleDiv{
    position: absolute;
    top: 0;
    left: 95%;
    height: 100%;
    width: 15%;
    z-index: 999;
}
.pageContent{
    overflow: auto;
    max-height: 90vh;
    min-height: 600px;
    margin-top: 3rem;
}
.pageContent::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

.pageContent::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #edeeef;
    border-radius: 10px;
}

.pageContent::-webkit-scrollbar-thumb {
    background: #d7d9db;
    border-radius: 10px;
}
.container::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

.container::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #edeeef;
    border-radius: 10px;
}

.container::-webkit-scrollbar-thumb {
    background: #d7d9db;
    border-radius: 10px;
}

.highlightRow{
    background: rgba(225, 237, 249, 0.8);
    position: relative;
}

.highlightRowEditable{
    background: #fff;
    border-top : 5px solid #5fafff !important;
    border-right : 5px solid #5457f7 !important;
    border-bottom : 5px solid #5fafff !important;
    border-left : 5px solid #5457f7 !important;
}

.resultContainer{
    display: flex;
    align-items: center;
    text-align: left;
    width: calc(65% - 32px);
    color: #1b1b1b;
    position: relative;
}

.resultInfoContainer{
    display: flex;
    align-items: center;
    text-align: right !important;
    color: #1b1b1b;
    position: relative;
    float:right;
}

.resultPopper{
    position: absolute;
    top: 100%;
    ul {
        list-style-type: none;
       margin: 0 !important;
       padding: 0 !important;
        li{
            padding: 0.5rem 1rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
    }
    .label{

    }
    .tickIcon{

    }
}
.confidence{
    display: inline-block !important;
    margin-left: 8px;
    font-family: OpenSans, sans-serif;
    font-size: 15px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #959daf;
}

.csvLink{
    color: inherit !important;
    text-decoration: none !important;
}
.menuItem:hover{
    background: rgba(0, 0, 0, 0.08);
}

.close-modal{position: absolute; right:20px; top:20px; cursor:pointer; z-index:9999}

div.modalBox > div:first-child{background:none!important;}

div.modalBox .react-draggable {
    background-color: #fff;
    left:56px!important;
    right:73px!important;
    top:auto!important;
    bottom:0px;
    border-radius: 20px 20px 0px 0px;
    z-index: 999;
    box-shadow: 0px 0px 65px -15px rgba(0,0,0,0.25);
    padding : 30px 50px;
}

.bottomPush{bottom:0!important}

div.modalBox .react-draggable div:first-child{}

/*body, html{overflow: visible!important; overflow-x: hidden!important; padding-right:0!important; }*/

.right-icon{display : inline-block; margin-left: 10px; color: #959daf; line-height: 30px; margin-top:-3px}

.view-edit-row{display:flex;}


.handler{
    background :#fff;
    -webkit-box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 5px 3px rgba(0,0,0,0.09);
    width: 90px;
    height: 20px;
    position: absolute;
    left:50%;
    top:-10px;
    margin-left:-45px;
}

#right_panel {
    position: absolute;
    width: 96px;
    padding-left: 4px;
    height: 100%;
    right: 0;
    background-color: #f0f0ff;
}

#right_panel:after {
    content: " ";
    background-color: #ccc;
    position: absolute;
    left: 0;
    width: 4px;
    height: 100%;
    cursor: w-resize;
}

.custom-box {
  overflow: hidden;
  position:absolute;
  left: 0;
  bottom: 0;
  width : 100%;
}

span.custom-handle {
  position: absolute;
  background :#ccc;
  box-shadow: 0px 0px 65px -15px rgba(0,0,0,0.25);
  width:50px;
  height: 10px;
  left:44%;
  top:79px;
  z-index: 999
}

.custom-handle-n,
.custom-handle-s {  cursor: ns-resize;}

div.modalBox table{position : relative;}

div.modalBox th{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    width: 100%;
    border-bottom:3px solid #f2f2f2;
  }


div.modalBox thead tr th{
    line-height: 1rem;
    padding: 2px 0;
    background: #fff;
}

.tableCell span{
  display: none;
}

.tableR{position : relative;}

.tableR .addIcon {
  display: none;
}

.tableR:hover .addIcon {
  display: block;
  position: absolute;
  left :-1px;
  top: 0;
  z-index: 999;
  background: #ffffff;
  height : 100%;
  width : 50px;
}

.iconsCont{display:inline-block; padding:0; background: #ffffff; position: absolute; top: 50%; margin-top:-9px;} //margin-top: calc(100% - 75%)}

.tableR:hover .addIcon .addRow {position: absolute; left: 5px; top: 30px; width: 213px}

.tableR .addRow button{font-size:12px; font-weight:normal; text-transform:none;}

.tableCell:hover span{
  display: inline-block;
}

.tableR:hover .addRow{
  display: inline-block;
}

.tableCell .MuiSvgIcon-fontSizeSmall{
  font-size: 1.0rem;
}

.tableR:hover .addIcon .iconsCont .MuiSvgIcon-fontSizeSmall {
  font-size: 0.9rem;
}

.modalDelete{
  width: 40rem;
  height: auto;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.75);
}

.modalCustomHeader{
  width: 30rem;
  height: auto;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 19px 0px rgba(0,0,0,0.75);
}

.modalTitle {
    background: linear-gradient(70deg, #5159F8, #5EAAFF) !important;
    color: white;
    text-align: left;
    padding:  20px;
    font-size: 1.5rem;
  }

#leftContainer {
  // border-top: 5px solid #f0f;
}
#leftContainer .left-side-custom:after {
  // background: pink;
  // border-top: 5px solid #ff0;
}

.addpopup {
  position: absolute;
  left: 0;
  top : 20px;
  padding:10px;
  max-width:auto;
}

.addpopup button{font-size:12px; font-weight:normal; text-transform:none;}

table tr td.tableR div{min-height:20px;}


.users-table {
    table-layout: fixed;
}

.ag-theme-material .ag-cell .ag-icon {
    display: inline-block;
    vertical-align: middle;
}
.ag-theme-material .ag-selection-checkbox span {
    position: relative;
}
.ag-theme-material .ag-icon-checkbox-checked, .ag-theme-material .ag-icon-checkbox-checked-readonly, .ag-theme-material .ag-icon-checkbox-unchecked, .ag-theme-material .ag-icon-checkbox-unchecked-readonly, .ag-theme-material .ag-icon-checkbox-indeterminate, .ag-theme-material .ag-icon-checkbox-indeterminate-readonly, .ag-theme-material .ag-icon-radio-button-on, .ag-theme-material .ag-icon-radio-button-off {
    height: 24px;
    width: 24px;
    font-size: 24px;
    line-height: 24px;

}
.ag-theme-material .ag-icon-checkbox-checked, .ag-theme-material .ag-icon-checkbox-indeterminate, .ag-theme-material .ag-icon-checkbox-unchecked {
    background-color:  transparent;
    line-height: 18px;
    border-radius: 3px;

}
.ag-theme-material .ag-icon {
    font-family: "agGridMaterial" !important;
}


.ag-body-viewport {
  border-left: 1px solid #ddd ;
  font-size: .7rem;
}

.ag-theme-material .ag-ltr .ag-cell, .ag-theme-material .ag-cell, .ag-theme-material * .ag-cell{
  border-right: 1px solid #ddd;
  /*position: relative;*/
}

.ag-theme-material .ag-rich-select .ag-rich-select-list {
  height: auto;
}

.ag-theme-material .ag-rich-select .ag-rich-select-value {
  border-bottom: 1px solid #ccc;
  background: transparent;
}


.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.MuiExpansionPanel-root {
    position: inherit !important;
}

.MuiExpansionPanelSummary-content {
	margin: 0 !important;
}

.MuiExpansionPanelSummary-content.Mui-expanded {
	margin: 0 !important;
}

.MuiExpansionPanelDetails-root {
	padding:0 !important;
}

.MuiExpansionPanelSummary-root.Mui-expanded {
	min-height: 0 !important;
}

.MuiExpansionPanelSummary-root {
	padding: 0 !important;
	width:100% !important;
}

.enrichedDetails {
	font-size: 15px;
	font-weight: normal !important;
	font-style: normal;
	line-height: 1.18;
	text-align: 'left';
	color: '#1e2a2a';
}

.MuiExpansionPanel-root.Mui-expanded {
	margin-top: 0 !important;
}

.MuiIconButton-edgeEnd {
	margin-right: 0 !important;
}

.MuiIconButton-root {
	padding:0 !important;
}

.enrichInfo {
	width: 31% !important;
}

.ag-theme-material .ag-icon-checkbox-checked {
  color: #33d;
}

.ag-cell-editor-autocomplete {
  z-index: 5000;
  font-size: .7rem;
  background: #fff;
  font-family: "Open Sans", sans-serif;
}

.ag-cell-editor-autocomplete div{
  padding: .4rem;
}

.ag-cell-editor-autocomplete div.selected{
  background-color: rgba(215, 227, 249, 0.8);
}

.ag-theme-material.ag-popup > div {
  z-index: 2000;
}

#noFileIcon svg{
  width: 5em;
  height: 5em;
  color: #ddd;  
}



body.tax_classifier .ag-menu-option.optional.tax_classifier {
  display: table-row;
}

body.tax_classifier button.optional.tax_classifier {
  display: inline-block;
}

.MuiSwitch-thumb {
  margin-top: 3px;
}

div.chartDiv.react-draggable {
  z-index: 1001;
}

div.tooltip {
  padding: .3rem .6rem .3rem .6rem;
  font-weight: bold;
  background: #eee;
  box-shadow: 2px 2px 11px #333;
}

div.chartDiv .chart-label {
  color: #000;
}

div.chartDiv .chart-line{
  cursor: pointer;
  text-align: left;
  margin: 0 0 .5rem 0;
}

div.chartDiv .chart-line label{
  cursor: pointer;
  margin: 0 .4rem 0 0 ;
}

div.chartLeft {
  float: left;
}
div.chartRight {
  float: right;
  max-width: 40%;
  min-height: 218px;
  height: 258px;
  overflow-y: scroll;
  padding: 0px 20px 0px 0px;
  margin-top: 20px;
}

.ag-theme-material .ag-cell.ag-cell-inline-editing{
    height: auto;
    padding: 0 10px;
}
.ag-theme-material .ag-input-wrapper input[type="text"]{border-bottom: 0; padding-bottom: 0;}
.ag-theme-material .ag-input-wrapper input:not([type]),
.ag-theme-material .ag-input-wrapper input[type="text"]:focus,
.ag-theme-material .ag-input-wrapper input[type="number"], .ag-theme-material .ag-input-wrapper input[type="tel"], .ag-theme-material .ag-input-wrapper input[type="date"], .ag-theme-material .ag-input-wrapper input[type="datetime-local"]{border-bottom: 0;}

#nvDoc {
  position: relative;
  display: block;
}
