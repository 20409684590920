@font-face {
    font-family: Jura-Regular;
    src: url('/src/assets/fonts/Jura/Jura-Regular.ttf');
}
@font-face {
    font-family: Jura-Bold;
    src: url('/src/assets/fonts/Jura/Jura-Bold.ttf');
}
@font-face {
    font-family: Jura-Light;
    src: url('/src/assets/fonts/Jura/Jura-Light.ttf');
}
@font-face {
    font-family: Jura-SemiBold;
    src: url('/src/assets/fonts/Jura/Jura-SemiBold.ttf');
}
@font-face {
    font-family: Jura-Medium;
    src: url('/src/assets/fonts/Jura/Jura-Medium.ttf');
}
@font-face {
    font-family: OpenSans-Bold;
    src: url('/src/assets/fonts/Open_Sans/OpenSans-Bold.ttf');
}
@font-face {
    font-family: OpenSans-BoldItalic;
    src: url('/src/assets/fonts/Open_Sans/OpenSans-BoldItalic.ttf');
}
@font-face {
    font-family: OpenSans-ExtraBold;
    src: url('/src/assets/fonts/Open_Sans/OpenSans-ExtraBold.ttf');
}
@font-face {
    font-family: OpenSans-ExtraBoldItalic;
    src: url('/src/assets/fonts/Open_Sans/OpenSans-ExtraBoldItalic.ttf');
}
@font-face {
    font-family: OpenSans-Italic;
    src: url('/src/assets/fonts/Open_Sans/OpenSans-Italic.ttf');
}
@font-face {
    font-family: OpenSans-Light;
    src: url('/src/assets/fonts/Open_Sans/OpenSans-Light.ttf');
}
@font-face {
    font-family: OpenSans-LightItalic;
    src: url('/src/assets/fonts/Open_Sans/OpenSans-LightItalic.ttf');
}
@font-face {
    font-family: OpenSans-Regular;
    src: url('/src/assets/fonts/Open_Sans/OpenSans-Regular.ttf');
}
@font-face {
    font-family: OpenSans-SemiBold;
    src: url('/src/assets/fonts/Open_Sans/OpenSans-SemiBold.ttf');
}
@font-face {
    font-family: OpenSans-SemiBoldItalic;
    src: url('/src/assets/fonts/Open_Sans/OpenSans-SemiBoldItalic.ttf');
}
@font-face {
    font-family: Raleway-Black;
    src: url('/src/assets/fonts/Raleway/Raleway-Black.ttf');
}
@font-face {
    font-family: Raleway-BlackItalic;
    src: url('/src/assets/fonts/Raleway/Raleway-BlackItalic.ttf');
}
@font-face {
    font-family: Raleway-Bold;
    src: url('/src/assets/fonts/Raleway/Raleway-Bold.ttf');
}
@font-face {
    font-family: Raleway-BoldItalic;
    src: url('/src/assets/fonts/Raleway/Raleway-BoldItalic.ttf');
}
@font-face {
    font-family: Raleway-ExtraBold;
    src: url('/src/assets/fonts/Raleway/Raleway-ExtraBold.ttf');
}
@font-face {
    font-family: Raleway-ExtraBoldItalic;
    src: url('/src/assets/fonts/Raleway/Raleway-ExtraBoldItalic.ttf');
}
@font-face {
    font-family: Raleway-ExtraLight;
    src: url('/src/assets/fonts/Raleway/Raleway-ExtraLight.ttf');
}
@font-face {
    font-family: Raleway-ExtraLightItalic;
    src: url('/src/assets/fonts/Raleway/Raleway-ExtraLightItalic.ttf');
}
@font-face {
    font-family: Raleway-Italic;
    src: url('/src/assets/fonts/Raleway/Raleway-Italic.ttf');
}
@font-face {
    font-family: Raleway-Light;
    src: url('/src/assets/fonts/Raleway/Raleway-Light.ttf');
}
@font-face {
    font-family: Raleway-LightItalic;
    src: url('/src/assets/fonts/Raleway/Raleway-LightItalic.ttf');
}
@font-face {
    font-family: Raleway-Medium;
    src: url('/src/assets/fonts/Raleway/Raleway-Medium.ttf');
}
@font-face {
    font-family: Raleway-MediumItalic;
    src: url('/src/assets/fonts/Raleway/Raleway-MediumItalic.ttf');
}
@font-face {
    font-family: Raleway-Regular;
    src: url('/src/assets/fonts/Raleway/Raleway-Regular.ttf');
}
@font-face {
    font-family: Raleway-SemiBold;
    src: url('/src/assets/fonts/Raleway/Raleway-SemiBold.ttf');
}
@font-face {
    font-family: Raleway-SemiBoldItalic;
    src: url('/src/assets/fonts/Raleway/Raleway-SemiBoldItalic.ttf');
}
@font-face {
    font-family: Raleway-Thin;
    src: url('/src/assets/fonts/Raleway/Raleway-Thin.ttf');
}
@font-face {
    font-family: Raleway-ThinItalic;
    src: url('/src/assets/fonts/Raleway/Raleway-ThinItalic.ttf');
}

*:not(i){
  font-family: 'Open Sans', sans-serif !important;
}

.Mui-focused{
    color: grey !important;
    border-color: grey !important;
.MuiOutlinedInput-notchedOutline{
    border-color: grey !important;
}
}
.Mui-error{
    color: grey !important;
    border-color: grey !important;
.MuiOutlinedInput-notchedOutline{
    border-color: grey !important;
}
.MuiFormHelperText-contained{
    color: #ef65b1 !important;
}
}
.MuiFormControl-root{
.MuiFormHelperText-contained{
  color: #ef65b1 !important;
  font-size: 10px;
  padding-left: 1.5rem;
  padding-top: 1px;
}
}
.description-box {
    font-family: 'Open Sans', sans-serif !important;
    width: 95%;
    margin: 10px 10px 10px 0 !important;
}
.description-box #description-helper-text{
    text-align: right;
    color: #686868 !important;
    font-size: 15px !important;
    font-family: 'Open Sans', sans-serif !important;
    margin-bottom: 24px;
}
.group-name {
    margin: 10px;
    width: 95%;
}
.group-name label.Mui-focused {
    color: #303f9f !important;
}
.MuiOutlinedInput-notchedOutline{
    border-radius: 10px !important;
}
.MuiSelect-outlined{
    padding: 0.75rem !important;
}
.padding-10{
    padding: 10px;
}
.top-right-drop-icon{
    //position: absolute;
    //right: 3px;
    //top: 7px;
    text-align: right;
}
.top-right-drop-icon:hover{
    cursor: pointer;
}
.mb-3{
    margin-bottom: 3rem;
}
.mt-3{
    margin-top: 3rem !important;
}
.display-none{
    display: none !important;
}
.display-header{
    display: flex !important;
    align-items: center;
    margin-left: -5px;
    //margin-bottom:8px;
}
.title-text{
    margin-left: 0.9rem;
}

.file-name{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 12px;
    color: #1e2a5a;
    font-family: Open Sans !important;
}

.file-name b {
    font-family: Open Sans !important;
}

.font-10 {
    font-size: 10px;
}

.file-mng-items {
    font-size: 15px;
    font-weight: 500;
    color: #1b1b1b;
    font-family: Raleway;
}

#swagger-container{
    min-height: calc( 100vh - 100px);
}
.blue-icon{
    color: #5EAEFF;
}

.blue-icons{
    color: #5EAEFF;
    width:25px!important;
}
.work-menu .MuiListItem-gutters{
    padding-left: 0 !important;
    align-items: center;
}
.infoMsg{
    text-align: center;
    margin: 2rem;
}

.moveModal{
    position: absolute;
    left: 100%;
    background: white;
    //padding: 1rem;
    width: 200px;
}
.redocDiv{
    min-height: calc( 100vh - 100px);
    overflow-x: hidden;
}
.MuiOutlinedInput-input span {
    display: none;
}
.successButton span {
    font-family: 'Open Sans', sans-serif !important;
    color: #959daf;
    font-size: 16px;
    font-weight: bold;
}

.no-text-transform {
    text-transform: none;
}
.filterSelect {
    font-size: 13px;
}
.filterSelect .MuiSelect-icon {
    margin-right: 0;
}
.filterSelect .MuiSelect-selectMenu {
    color: #959daf;
    font-family: 'Open Sans', sans-serif !important;
    padding-left: 24px !important;
    height: 0;
}

.MuiSelect-selectMenu{min-height:20px!important;}

.groupSelect {
    margin: 10px !important;
    width: 95%;
}

.opensans-font span{
    font-family: 'Open Sans', sans-serif !important;
}

.fontBold {
    font-weight: bold;
}
.MuiTablePagination-root {
    font-family: 'Open Sans', sans-serif !important;
}

.MuiTablePagination-root div span:nth-child(4){
    font-weight: bold;
}
.grid-icon{
    font-size: 20px;
    margin: 5px 0 0 4px;
}

.disableButton:hover{
    cursor: not-allowed !important;
}
.customScroll::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

.customScroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #edeeef;
    border-radius: 10px;
}

.customScroll::-webkit-scrollbar-thumb {
    background: #d7d9db;
    border-radius: 10px;
}
.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation8.MuiPopover-paper.MuiPaper-rounded {
    min-width: 150px;
    margin: 47px 2px;
    }

.hide {
  display: none !important;
}
// we can use the .optional class to declare a ui element or whole component optional from the core ui.
// this way we prevent some users from seeing ui elements that only make sense to another capability.
.optional, body .optional, body * .optional  {
  display: none ;
}
